.filter-wrapper {
  margin: 20px auto; }
  .filter-wrapper:first-child {
    margin-top: 0; }
  .filter-wrapper:last-child {
    margin-bottom: 0; }
  .filter-wrapper .filter-name {
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;
    font-size: 12px; }
  .filter-wrapper .filters .filter {
    position: relative; }
  .filter-wrapper .filters .ant-select,
  .filter-wrapper .filters .ant-select-tree {
    font-size: 13px; }
