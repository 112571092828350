@charset "UTF-8";
/* float */
.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

/* display */
.show {
  display: block !important; }

.hide {
  display: none !important; }

.inline-block {
  display: inline-block !important; }

/* visibility */
.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* cursor */
.cursor-pointer {
  cursor: pointer !important; }

.cursor-default {
  cursor: default !important; }

/* position */
.position-relative {
  position: relative; }

.position-absolute {
  position: absolute; }

/*
    Generate the boilerplate for margin from 5px to 35px
    Format like below:

    Format:
    .mt-15 {
        margin-top: 15px !important;
    }
    .mb-15 {
        margin-bottom: 15px !important;
    }
    .ml-15 {
        margin-left: 15px !important;
    }
    .mr-15 {
        margin-right: 15px !important;
    }
*/
.mt-1 {
  margin-top: 1px !important; }

.mt-2 {
  margin-top: 2px !important; }

.mt-3 {
  margin-top: 3px !important; }

.mt-4 {
  margin-top: 4px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mt-6 {
  margin-top: 6px !important; }

.mt-7 {
  margin-top: 7px !important; }

.mt-8 {
  margin-top: 8px !important; }

.mt-9 {
  margin-top: 9px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-11 {
  margin-top: 11px !important; }

.mt-12 {
  margin-top: 12px !important; }

.mt-13 {
  margin-top: 13px !important; }

.mt-14 {
  margin-top: 14px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-16 {
  margin-top: 16px !important; }

.mt-17 {
  margin-top: 17px !important; }

.mt-18 {
  margin-top: 18px !important; }

.mt-19 {
  margin-top: 19px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-21 {
  margin-top: 21px !important; }

.mt-22 {
  margin-top: 22px !important; }

.mt-23 {
  margin-top: 23px !important; }

.mt-24 {
  margin-top: 24px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mt-26 {
  margin-top: 26px !important; }

.mt-27 {
  margin-top: 27px !important; }

.mt-28 {
  margin-top: 28px !important; }

.mt-29 {
  margin-top: 29px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-31 {
  margin-top: 31px !important; }

.mt-32 {
  margin-top: 32px !important; }

.mt-33 {
  margin-top: 33px !important; }

.mt-34 {
  margin-top: 34px !important; }

.mt-35 {
  margin-top: 35px !important; }

.mb-1 {
  margin-bottom: 1px !important; }

.mb-2 {
  margin-bottom: 2px !important; }

.mb-3 {
  margin-bottom: 3px !important; }

.mb-4 {
  margin-bottom: 4px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.mb-6 {
  margin-bottom: 6px !important; }

.mb-7 {
  margin-bottom: 7px !important; }

.mb-8 {
  margin-bottom: 8px !important; }

.mb-9 {
  margin-bottom: 9px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-11 {
  margin-bottom: 11px !important; }

.mb-12 {
  margin-bottom: 12px !important; }

.mb-13 {
  margin-bottom: 13px !important; }

.mb-14 {
  margin-bottom: 14px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-16 {
  margin-bottom: 16px !important; }

.mb-17 {
  margin-bottom: 17px !important; }

.mb-18 {
  margin-bottom: 18px !important; }

.mb-19 {
  margin-bottom: 19px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-21 {
  margin-bottom: 21px !important; }

.mb-22 {
  margin-bottom: 22px !important; }

.mb-23 {
  margin-bottom: 23px !important; }

.mb-24 {
  margin-bottom: 24px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-26 {
  margin-bottom: 26px !important; }

.mb-27 {
  margin-bottom: 27px !important; }

.mb-28 {
  margin-bottom: 28px !important; }

.mb-29 {
  margin-bottom: 29px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-31 {
  margin-bottom: 31px !important; }

.mb-32 {
  margin-bottom: 32px !important; }

.mb-33 {
  margin-bottom: 33px !important; }

.mb-34 {
  margin-bottom: 34px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.ml-1 {
  margin-left: 1px !important; }

.ml-2 {
  margin-left: 2px !important; }

.ml-3 {
  margin-left: 3px !important; }

.ml-4 {
  margin-left: 4px !important; }

.ml-5 {
  margin-left: 5px !important; }

.ml-6 {
  margin-left: 6px !important; }

.ml-7 {
  margin-left: 7px !important; }

.ml-8 {
  margin-left: 8px !important; }

.ml-9 {
  margin-left: 9px !important; }

.ml-10 {
  margin-left: 10px !important; }

.ml-11 {
  margin-left: 11px !important; }

.ml-12 {
  margin-left: 12px !important; }

.ml-13 {
  margin-left: 13px !important; }

.ml-14 {
  margin-left: 14px !important; }

.ml-15 {
  margin-left: 15px !important; }

.ml-16 {
  margin-left: 16px !important; }

.ml-17 {
  margin-left: 17px !important; }

.ml-18 {
  margin-left: 18px !important; }

.ml-19 {
  margin-left: 19px !important; }

.ml-20 {
  margin-left: 20px !important; }

.ml-21 {
  margin-left: 21px !important; }

.ml-22 {
  margin-left: 22px !important; }

.ml-23 {
  margin-left: 23px !important; }

.ml-24 {
  margin-left: 24px !important; }

.ml-25 {
  margin-left: 25px !important; }

.ml-26 {
  margin-left: 26px !important; }

.ml-27 {
  margin-left: 27px !important; }

.ml-28 {
  margin-left: 28px !important; }

.ml-29 {
  margin-left: 29px !important; }

.ml-30 {
  margin-left: 30px !important; }

.ml-31 {
  margin-left: 31px !important; }

.ml-32 {
  margin-left: 32px !important; }

.ml-33 {
  margin-left: 33px !important; }

.ml-34 {
  margin-left: 34px !important; }

.ml-35 {
  margin-left: 35px !important; }

.mr-1 {
  margin-right: 1px !important; }

.mr-2 {
  margin-right: 2px !important; }

.mr-3 {
  margin-right: 3px !important; }

.mr-4 {
  margin-right: 4px !important; }

.mr-5 {
  margin-right: 5px !important; }

.mr-6 {
  margin-right: 6px !important; }

.mr-7 {
  margin-right: 7px !important; }

.mr-8 {
  margin-right: 8px !important; }

.mr-9 {
  margin-right: 9px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mr-11 {
  margin-right: 11px !important; }

.mr-12 {
  margin-right: 12px !important; }

.mr-13 {
  margin-right: 13px !important; }

.mr-14 {
  margin-right: 14px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mr-16 {
  margin-right: 16px !important; }

.mr-17 {
  margin-right: 17px !important; }

.mr-18 {
  margin-right: 18px !important; }

.mr-19 {
  margin-right: 19px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mr-21 {
  margin-right: 21px !important; }

.mr-22 {
  margin-right: 22px !important; }

.mr-23 {
  margin-right: 23px !important; }

.mr-24 {
  margin-right: 24px !important; }

.mr-25 {
  margin-right: 25px !important; }

.mr-26 {
  margin-right: 26px !important; }

.mr-27 {
  margin-right: 27px !important; }

.mr-28 {
  margin-right: 28px !important; }

.mr-29 {
  margin-right: 29px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mr-31 {
  margin-right: 31px !important; }

.mr-32 {
  margin-right: 32px !important; }

.mr-33 {
  margin-right: 33px !important; }

.mr-34 {
  margin-right: 34px !important; }

.mr-35 {
  margin-right: 35px !important; }

/* margin left */
.ml-neg-sidebar {
  margin-left: -264px; }

/* font weight */
.bold {
  font-weight: bold; }

.bolder {
  font-weight: bolder; }

.lighter {
  font-weight: lighter; }

.italic {
  font-style: italic; }

.center {
  display: flex;
  align-items: center;
  justify-content: center; }

.red {
  color: #ff0000; }

.green {
  color: #73d13d; }

* {
  margin: 0;
  padding: 0;
  border: 0; }

*,
*:before,
*:after {
  box-sizing: border-box; }

body {
  font-family: Helvetica Neue‘, Arial, Verdana, sans-serif !important;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.63);
  background: #ececec; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(0, 0, 0, 0.63); }

/* scroller*/
::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px; }

::-webkit-scrollbar-thumb {
  background-color: #166499; }

/* scroller end*/
.span-link {
  color: #006699;
  cursor: pointer;
  transition: color 0.3s ease; }
  .span-link:hover {
    color: #3d8bb1; }
  .span-link:active {
    color: #005685; }

.ant-rate-star {
  margin: 0 2px 0 2px; }

.button-label {
  display: inline-block;
  margin-right: 5px;
  background: #fbfbfb;
  padding: 5px 11px;
  border-radius: 4px;
  border: 1px solid;
  border-color: #d9d9d9; }

.ant-layout-header {
  padding: 0 11px !important; }
