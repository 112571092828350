.review {
  background: #fbfbfb;
  padding: 20px 24px 20px 24px;
  border-radius: 4px;
  min-height: 250px;
  /* review-info */
  /* review-info end */
  /* review-controls */
  /* review-controls end */
  /* review-snippets */
  /* review-snippets end */ }
  .review .review-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
    .review .review-info .info-title {
      color: #999;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.2px; }
    .review .review-info .info-value {
      font-size: 22px;
      color: #475058;
      font-weight: bold;
      text-transform: uppercase; }
  .review .review-controls {
    overflow: auto; }
    .review .review-controls .ant-btn-group {
      float: left; }
    .review .review-controls .text-search {
      width: auto;
      float: left; }
    .review .review-controls .help-icon {
      font-size: 16px;
      margin-top: 8px;
      margin-left: 6px; }
    .review .review-controls .keywords-list {
      clear: both;
      margin-bottom: 10px; }
      .review .review-controls .keywords-list .title {
        font-weight: 700;
        letter-spacing: 1px; }
      .review .review-controls .keywords-list .keyword {
        margin-bottom: 4px; }
      .review .review-controls .keywords-list .search-type-desc {
        margin-left: 6px;
        font-weight: 700;
        letter-spacing: 1px; }
      .review .review-controls .keywords-list .clear-search {
        letter-spacing: 1px;
        float: right; }
      .review .review-controls .keywords-list .logic-param {
        margin-right: 8px;
        color: #888;
        text-transform: lowercase; }
  .review .review-snippets {
    background: #fbfbfb; }
    .review .review-snippets .review-text {
      margin-bottom: 4px;
      word-break: break-word; }
    .review .review-snippets .review-range {
      letter-spacing: 1px;
      margin-right: 5px; }
  .review .pei-score {
    float: right;
    margin-right: 20px; }
  .review .download-csv {
    float: right; }
  .review .download-progress-bar {
    margin-left: auto;
    width: 200px; }

.frequency-histogram {
  height: 400px;
  width: 600px; }
