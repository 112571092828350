.insights {
  display: flex; }
  .insights .transition, .insights .left-wrapper, .insights .right-wrapper {
    transition: margin 0.3s ease; }
  .insights .left-wrapper {
    padding: 24px 14px;
    background: #fbfbfb;
    border-radius: 4px;
    position: relative;
    width: 240px;
    /* flex-grow: 0; flex-shrink: 0; flex-basis: $filter-wrapper-width */
    height: 100%;
    margin-right: 24px; }
  .insights .select-all {
    display: inline-block;
    color: #286fbe !important;
    cursor: pointer !important; }
  .insights .right-wrapper {
    flex: auto;
    /* flex-grow: 1; flex-shrink: 1; flex-basis: auto */
    height: 100%;
    width: calc(100% - 240px - 24px); }
  .insights .filter-set-submit {
    float: right;
    position: sticky;
    top: 1px;
    z-index: 99; }
  .insights .filter-set-title {
    font-size: 18px;
    text-transform: uppercase; }
  .insights .reset-filters {
    letter-spacing: 1px;
    font-size: 12px; }
  .insights .insights-nav {
    margin-bottom: 14px; }
