.product-details {
  background: #fbfbfb;
  padding: 20px 24px 4px 24px;
  border-radius: 4px;
  min-height: 250px;
  display: flex;
  flex-wrap: wrap; }
  .product-details .transition, .product-details .product-details-left, .product-details .product-details-right {
    transition: margin 0.3s ease; }
  .product-details .product-details-left {
    position: relative;
    flex: 1 1 150px;
    min-width: 100px; }
  .product-details .product-details-right {
    position: relative;
    flex: 1 1 600px;
    min-width: 400px; }
  .product-details .product-table .ant-table-thead > tr > th,
  .product-details .product-table .ant-table-tbody > tr > td {
    padding: 8px 4px;
    text-align: center; }
    .product-details .product-table .ant-table-thead > tr > th .product-value,
    .product-details .product-table .ant-table-tbody > tr > td .product-value {
      margin-bottom: -6px;
      width: 24px; }
