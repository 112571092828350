.product {
  background: #fbfbfb;
  padding: 20px 24px 20px 24px;
  border-radius: 4px;
  min-height: 250px; }
  .product .product-table .ant-table-thead > tr > th,
  .product .product-table .ant-table-tbody > tr > td {
    padding: 8px 4px;
    text-align: center; }
    .product .product-table .ant-table-thead > tr > th .product-value,
    .product .product-table .ant-table-tbody > tr > td .product-value {
      margin-bottom: -6px;
      width: 24px;
      cursor: pointer; }
  .product .product-range {
    letter-spacing: 1px;
    margin-right: 5px; }

.product-table .ant-table-thead > tr > th,
.product-table .ant-table-tbody > tr > td {
  padding: 8px 4px;
  text-align: center; }
  .product-table .ant-table-thead > tr > th .product-value,
  .product-table .ant-table-tbody > tr > td .product-value {
    margin-bottom: -6px;
    width: 24px;
    cursor: pointer; }

.product-controls {
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  overflow: hidden; }
  .product-controls .ant-btn-group {
    float: left; }
  .product-controls .text-search {
    width: auto;
    float: left; }
  .product-controls .keywords-list {
    clear: both;
    margin-bottom: 10px; }
    .product-controls .keywords-list .title {
      font-weight: 700;
      letter-spacing: 1px; }
    .product-controls .keywords-list .keyword {
      margin-bottom: 4px; }
    .product-controls .keywords-list .search-type-desc {
      margin-left: 6px;
      font-weight: 700;
      letter-spacing: 1px; }
    .product-controls .keywords-list .clear-search {
      letter-spacing: 1px;
      float: right; }
    .product-controls .keywords-list .logic-param {
      margin-right: 8px;
      color: #888;
      text-transform: lowercase; }
